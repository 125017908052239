<template>
  <b-sidebar
    id="add-new-page-sidebar"
    :visible="isAddNewPageSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-page-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          إضافة {{ $t("page") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- name -->

          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              label="اسم"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="pageData.name"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end name -->
          <!-- description -->

          <validation-provider
            #default="validationContext"
            name="description"
            rules="required"
          >
            <b-form-group
              label="وصف"
              label-for="description"
            >
              <b-form-input
                id="description"
                v-model="pageData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- end description -->

          <!-- layout_id -->
          <validation-provider
            #default="validationContext"
            name="layout_id"
            rules="required"
          >
            <b-form-group
              label="قالب الصفحة"
              label-for="layout_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="pageData.layout_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="layouts"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="layout_id"
                @input="layoutChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- end layout_id -->

          <!-- report_id -->
          <validation-provider
            #default="validationContext"
            name="report_id"
            rules="required"
          >
            <b-form-group
              label="التقرير"
              label-for="report_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="pageData.report_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="reports"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="report_id"
                @input="reportChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- end report_id -->

          <!-- orientation -->
          <validation-provider
            #default="validationContext"
            name="orientation"
            rules="required"
          >
            <b-form-group
              label="إتجاه الصفحة"
              label-for="orientation"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="pageData.orientation"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="orientations"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="orientation"
                @input="orientationChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- end orientation -->

          <!-- size -->
          <validation-provider
            #default="validationContext"
            name="size"
            rules="required"
          >
            <b-form-group
              label="مقاس الصفحة"
              label-for="size"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="pageData.size"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="sizes"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="size"
                @input="sizeChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- end size -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BButton,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPageSidebarActive',
    event: 'update:is-add-new-page-sidebar-active',
  },
  props: {
    isAddNewPageSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    orientationChanged(orientation) {
      this.pageData.orientation = orientation;
    },
    sizeChanged(size) {
      this.pageData.size = size;
    },
    reportChanged(report) {
      this.pageData.report_id = report;
    },
    layoutChanged(layout) {
      this.pageData.layout_id = layout;
    },

    onSubmit() {
      this.$refs.refFormObserver
        .validate()
        .then(async (success) => {
          if (success) {
            store.dispatch('page/addPage', this.pageData).then((response) => {
              this.$toast.success(response.data.message);
              this.$emit('refetch-data');
              this.$emit('update:is-add-new-page-sidebar-active', false);
            })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 422) {
                    this.$toast.error(err.response.data.message);
                    this.$refs.refFormObserver.setErrors(err.response.data.errors);
                  } else {
                    this.$toast.error(err.response.data.error);
                  }
                }
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  setup(props, { emit }) {
    const blankPageData = {
      layout_id: '',
      report_id: '',
      name: '',
      description: '',
    };

    const pageData = ref(JSON.parse(JSON.stringify(blankPageData)));
    const resetpageData = () => {
      pageData.value = JSON.parse(JSON.stringify(blankPageData));
    };

    const layouts = ref([]);
    const res = store.dispatch('page/fetchLayouts').then((response) => {
      const { data } = response;
      layouts.value = data.map((c) => ({
        label: c.description,
        value: c.id,
        ...c,
      }));
    });
    const reports = ref([]);
    const ress = store.dispatch('page/fetchReports').then((response) => {
      const { data } = response;
      reports.value = data.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
    });

    const sizes = [
      {
        label: 'A4',
        value: 'A4',
      },
      {
        label: 'A5',
        value: 'A5',
      },
    ];

    const orientations = [
      {
        label: 'عمودي',
        value: 'Portrait',
      },
      {
        label: 'أفقي',
        value: 'Landscape',
      },
    ];
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetpageData);

    return {
      orientations,
      sizes,
      pageData,
      layouts,
      reports,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-page-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
